// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA-WWLmaUQtX2ehDaO42fwUPTltLWg8W7g",
  authDomain: "gasbill-f25b7.firebaseapp.com",
  databaseURL: "https://gasbill-f25b7-default-rtdb.firebaseio.com",
  projectId: "gasbill-f25b7",
  storageBucket: "gasbill-f25b7.appspot.com",
  messagingSenderId: "1038403248577",
  appId: "1:1038403248577:web:c26ffab2457b51463fcf40",
  measurementId: "G-BTJMGXEFNW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };